<template>
  <svg width="300" height="225" viewBox="-8 0 122 80">
    <path
      ref="firstPath"
      d="m 47.285821,67.720258 c 0.556842,-9.85042 0.24723,-6.724926 0.227474,-10.383987
      -0.0098,-1.821957 -0.213016,-5.461799 -0.213016,-5.461799 0,0 0.310133,-2.932236
      0.01704,-4.353512 -0.14628,-0.709346 -0.828347,-1.268954 -0.867399,-1.992172
      -0.05826,-1.07893 0.793162,-2.02756 0.966472,-3.094072 0.07965,-0.490162
      0.210151,-1.027145 0.02894,-1.489493 -0.19232,-0.490694 -0.615612,-0.923679
      -1.096682,-1.138944 -0.673581,-0.301408 -1.653931,-0.564812 -2.212295,-0.08233
      -1.456889,1.258896 -0.225665,3.858814 -0.624665,5.742466 -0.406066,1.917011
      -1.312925,3.699015 -1.788401,5.599999 -0.401788,1.606376 -0.894548,4.886378
      -0.894548,4.886378 l 5.209532,-0.05699 0.01558,1.270238 -5.258798,0.0062 c
      0,0 -0.38788,2.880533 -0.47298,4.329933 -0.117133,1.994972 -0.0509,5.995008
      -0.0509,5.995008"
      stroke="#008080"
      stroke-width="0.465"
      stroke-linecap="round"
    />
    <path
      d="m 54.471101,30.330383 c 0,0 -0.184329,2.340734 -0.80617,3.278854
      -1.678945,2.532885 -7.305928,5.452769 -7.305928,5.452769 0,0 1.54116,1.486455
      1.732818,2.475211 0.119888,0.618497 -0.42692,1.841182 -0.42692,1.841182 l
      1.614567,2.794432 3.023018,-3.788225"
      stroke="#00ffff"
      stroke-width="0.465"
      stroke-linecap="round"
    />
    <path
      d="m 39.393972,43.822075 1.280971,3.193801 2.467102,-4.457079 c 0.0064,-0.01159
      -0.28313,-1.013696 0.09949,-2.04281 0.207168,-0.557204 1.262772,-1.189703
      1.146162,-1.353429 -0.242563,-0.400938 -0.918736,-0.938175 -1.414303,-1.367988
      -0.632528,-0.548602 -1.458909,-0.876269 -2.009618,-1.506963 -0.50375,-0.576915
      -1.118107,-2.007285 -1.118107,-2.007285"
      stroke="#00ffff"
      stroke-width="0.465"
      stroke-linecap="round"
    />
    <path
      d="m 16.100966,78.875246 c -0.569822,-0.01448 -0.297259,-1.138571
      -0.193438,-1.699043 0.25647,-1.38454 1.60582,-2.38202 1.949515,-3.747524
      0.299989,-1.191862 -0.08268,-2.46488 0.04964,-3.686773 0.276395,-2.552372
      1.547284,-7.54486 1.547284,-7.54486 0,0 1.891166,-6.63452 2.417036,-10.034637
      0.402532,-2.602644 -0.796472,-5.621794 0.556752,-7.881123 1.323952,-2.210457
      6.540815,-4.119277 6.540815,-4.119277 0,0 1.591631,0.284748 2.204458,-0.122778
      0,0 2.761575,-1.02171 5.237509,-2.353257 1.23223,-0.662688 2.878071,-3.055263
      2.878071,-3.055263 0,0 0.378932,4.307448 0.180789,5.962531 -0.74384,6.213279
      -0.995647,6.954209 -1.301167,10.827343 -0.236574,2.999096 -0.264898,2.730663
      -0.414454,9.111033 -0.05524,2.356494 1.223111,4.563907 2.093912,6.7543
      1.382492,3.477491 4.945558,10.078668 4.945558,10.078668 l 4.474744,-16.575949
      2.80802,-15.343815 1.255562,-7.32581 1.595149,-2.286568 0.224814,-3.257421
      -0.680434,-2.24464 3.061294,2.359595 c 0,0 0.39969,0.656281 3.571853,2.137255
      4.996962,2.332911 8.281454,2.408259 8.764986,2.606244 1.332087,0.545431
      6.009171,0.388982 6.652913,2.595737 1.628085,5.581094 1.810843,7.251487
      2.784536,10.859265 1.827055,6.769698 4.563195,14.142728 5.736511,20.238453
      0.395482,2.054645 0.681262,6.240001 0.681262,6.240001 l -0.345765,1.499465 c
      0.580109,-1.168222 -44.032624,0.650262 -69.277728,0.0088 z"
      stroke="#008000"
      stroke-width="0.465"
      stroke-linecap="butt"
    />
    <path
      stroke="#008000"
      stroke-width="0.264583px"
      stroke-linecap="butt"
      d="m 17.237193,75.604819 c 0,0 3.426579,-1.313991 5.229898,-1.420601
      2.290025,-0.135384 6.807716,1.008914 6.807716,1.008914"
    />
    <path
      stroke="#008000"
      stroke-width="0.264583px"
      stroke-linecap="butt"
      d="m 23.653349,44.923228 c 0,0 0.01319,4.597985 0.339869,6.866072 0.382403,2.654968
      1.288943,5.207884 1.886537,7.822835 0.793486,3.472137 1.179452,7.048981
      2.255629,10.444154 0.965667,3.046531 3.74428,8.826387 3.74428,8.826387"
    />
    <path
      stroke="#008000"
      stroke-width="0.264583px"
      stroke-linecap="butt"
      d="m 70.805298,77.059678 5.066136,-4.537242"
    />
    <path
      stroke="#008000"
      stroke-width="0.264583px"
      stroke-linecap="butt"
      d="m 69.31203,48.385904 c 0,0 0.105803,8.092717 0.126008,12.139327 0.0131,2.624539
      -0.591159,5.315872 -0.0031,7.873716 0.366081,1.592322 2.142379,4.408599
      2.142379,4.408599 l 1.467829,2.164204"
    />
    <path
      stroke="#008000"
      stroke-width="0.264583px"
      stroke-linecap="butt"
      d="m 71.228431,73.211639 -0.535795,4.551809"
    />
    <path
      d="M 36.528658,19.235726 C 35.951285,18.735931 35.297924,18.21396 35.03836,17.49578
      34.507247,16.02626 35.107,12.808625 35.107,12.808625 c 0,0 0.734568,-2.429394
      1.195359,-3.6103956 0.462644,-1.1857492 0.811834,-2.4589205 1.571905,-3.4798674
      0.77013,-1.0344589 1.760615,-1.9742899 2.921016,-2.5370292 1.070386,-0.5190865
      2.322392,-0.5474404 3.506012,-0.6666915 1.494927,-0.1506157 3.061744,-0.468571
      4.507111,-0.058197 1.248609,0.3545098 2.53743,1.0285956 3.286228,2.0887864
      0.37345,0.528752 0.433779,1.8929419 0.433779,1.8929419 0,0 1.16614,-0.049883
      1.658191,0.2115971 0.724084,0.3847846 1.623516,1.8480812 1.623516,1.8480812
      0,0 1.046404,3.0537141 1.337315,4.6353021 0.178959,0.972942 0.160555,2.072951
      -0.09549,3.091684 -0.185127,0.736567 -0.963863,1.733457 -0.963863,1.733457 0,0
      -0.505005,-1.36091 -0.626176,-2.072266 C 55.24664,14.62228 55.499774,13.309024
      55.292777,12.043895 55.178892,11.347853 55.035453,10.634922 54.707286,10.010622
      54.076412,8.8104578 53.119815,7.7892662 52.125778,6.8671561 51.550736,6.3337226
      50.200468,5.5143042 50.200468,5.5143042 l 0.364639,2.1212447 c 0,0
      -3.511322,0.038254 -5.170573,-0.4517408 -0.80985,-0.2391576 -1.394167,-1.190925
      -2.238576,-1.1858548 -0.907856,0.00545 -1.700352,0.7148413 -2.391293,1.3037666
      -0.907631,0.7736217 -1.862976,1.6830048 -2.188626,2.8302781 -0.397751,1.401285
      0.721261,2.913083 0.458415,4.345813 -0.135138,0.736615 -1.013455,2.005163
      -1.013455,2.005163 l 0.184185,1.226694 -0.01259,5.430156 c 0,0 -0.346175,-2.563395
      -1.098311,-3.545944 -0.135647,-0.177202 -0.396899,-0.212099 -0.565625,-0.358154 z"
      stroke="#ff6600"
      stroke-width="0.365"
      stroke-linecap="butt"
    />
    <path
      d="m 38.443093,23.338867 c 0,0 -0.08044,1.930442 0.09477,2.872204 0.180307,0.96913
      0.471546,1.943705 0.972011,2.792974 0.901804,1.530324 2.145672,2.888101
      3.554039,3.970526 0.969554,0.745168 2.101001,1.305186 3.270928,1.660969
      0.902702,0.274518 1.894783,0.569791 2.810011,0.340467 0.991587,-0.248456
      1.788972,-1.037021 2.510828,-1.76083 0.97788,-0.980525 1.808793,-2.144542
      2.376154,-3.407783 0.386204,-0.859891 0.480859,-1.824383 0.659477,-2.749944
      0.105601,-0.5472 0.171422,-1.101454 0.243081,-1.654124 0.182207,-1.405288
      0.222746,-2.830114 0.474998,-4.224532"
      stroke="#ff00ff"
      stroke-width="0.365"
      stroke-linecap="round"
    />
    <path
      d="m 39.431711,29.315391 0.642212,4.624335"
      stroke="#ff00ff"
      stroke-width="0.365"
      stroke-linecap="round"
    />
    <path
      d="m 54.15817,29.59641 v 0.846125"
      stroke="#ff00ff"
      stroke-width="0.365"
      stroke-linecap="round"
    />
    <path
      d="m 37.943205,23.286535 c -0.23219,-1.122188 -0.318447,-2.34373 -0.938935,-3.307168
      -0.451139,-0.700488 -1.074847,-1.570814 -1.906804,-1.616162 -0.300016,-0.01635
      -0.601655,0.241595 -0.746895,0.50462 -0.416105,0.753554 -0.20593,1.728972
      -0.08373,2.58106 0.07824,0.545565 0.295324,1.065811 0.515895,1.570896
      0.219567,0.502786 0.428263,1.03418 0.797984,1.439531 0.568349,0.623121
      1.255387,1.538617 2.091023,1.424545 0.272083,-0.03714 0.454403,-0.365811
      0.531609,-0.629341 0.186037,-0.63501 -0.126076,-1.320006 -0.260147,-1.967981 z"
      stroke="#ff00ff"
      stroke-width="0.365"
      stroke-linecap="round"
    />
    <path
      d="m 55.842966,20.962078 c 0.178392,-0.904233 -0.03658,-2.053418 0.62711,-2.692933
      0.181698,-0.17508 0.531137,-0.281109 0.743104,-0.144225 0.345749,0.223277
      0.2606,0.79067 0.284246,1.201567 0.05331,0.926409 -0.09861,1.865002
      -0.325866,2.764686 -0.182393,0.722081 -0.496695,1.407762 -0.823574,2.076954
      -0.303234,0.620785 -0.3817,1.962093 -1.05003,1.786996 -0.471811,-0.123611
      -0.06208,-0.976439 -0.02748,-1.462945 0.08456,-1.18907 0.341758,-2.360569
      0.57249,-3.5301 z"
      stroke="#ff00ff"
      stroke-width="0.365"
      stroke-linecap="round"
    />
    <path
      d="m 40.221008,18.022767 c 0.712137,-0.965586 2.281988,-0.863617 3.480588,-0.917041
      0.890377,-0.03969 2.00354,-0.179576 2.635901,0.44849 0.333734,0.331467
      0.247077,0.919105 0.246171,1.389476 -0.0012,0.617628 -1.15e-4,1.301233
      -0.330266,1.823216 -0.221999,0.350991 -0.603583,0.65362 -1.012531,0.726007
      -1.416111,0.250662 -3.205719,0.174455 -4.460101,-0.01448 -0.351003,-0.05287
      -0.454389,-0.963075 -0.454389,-0.963075 0,0 -0.598974,-1.823319 -0.105373,-2.492593
      z"
      stroke="#aa0000"
      stroke-width="0.365"
      stroke-linecap="butt"
    />
    <path
      d="m 49.405944,17.269965 c 0.92812,-1.033675 2.762606,-0.42926 4.150738,-0.374681
      0.442171,0.01739 0.912505,0.02571 1.308709,0.22279 0.345861,0.172037
      0.671517,0.448288 0.84433,0.793763 0.283428,0.566609 0.383562,1.279187
      0.211265,1.888852 -0.146362,0.517893 -0.495149,1.068242 -0.992751,1.273248
      -1.102543,0.454234 -2.639282,0.150876 -3.708065,0.05411 -0.542347,-0.04911
      -0.919909,-0.348945 -1.228399,-0.706699 -0.330572,-0.383362 -0.456875,-0.916878
      -0.555439,-1.413395 -0.112819,-0.568327 -0.417493,-1.306854 -0.03039,-1.737985 z"
      stroke="#aa0000"
      stroke-width="0.365"
      stroke-linecap="butt"
    />
    <path
      stroke="#00ff00"
      stroke-width="0.165"
      stroke-linecap="butt"
      d="m 10.081157,78.588504 c -0.7222254,-2.990038 2.557463,-5.636441
      3.273415,-8.627987 1.669202,-6.974626 1.023298,-14.334572 2.301861,-21.391266
      0.68697,-3.791552 0.174988,-8.447021 2.874342,-11.196799 3.927193,-4.000553
      14.029759,0.682307 16.209643,-4.482514 1.693878,-4.013323 -5.200364,-7.237154
      -6.291044,-11.454547 -1.269951,-4.910587 -1.857744,-10.473715 -0.05931,-15.2163165
      1.285463,-3.3898549 3.992425,-6.50861139 7.243347,-8.1133035 5.579021,-2.7538682
      12.730929,-3.4286268 18.612477,-1.3996736 4.400461,1.5180236 8.242335,5.2438658
      10.317927,9.4104432 2.195841,4.4079664 2.152052,9.8476714 1.371591,14.7100544
      -0.390435,2.432467 -3.907016,4.502392 -2.954705,6.774492 2.308229,5.50716
      12.015416,2.741014 16.729149,6.406727 2.43236,1.891566 4.012824,4.766899
      5.330219,7.552374 1.82865,3.866463 2.616772,8.166992 3.409705,12.369938
      1.501441,7.958398 4.159038,16.233625 2.521632,24.165165 -0.395797,1.917228
      -0.915328,5.102446 -2.872883,5.122334 -21.55343,0.218978 -50.371205,2.835477
      -72.648543,0.216 -2.394119,-0.281512 -4.802834,-2.501895 -5.368826,-4.845121 z"
    />
    <path
      stroke="#00d400"
      stroke-width="0.165"
      stroke-linecap="butt"
      d="M 12.259458,90.686829 C -4.8185944,82.050986 -0.12965497,48.022654
      9.2431535,30.108829 10.781846,27.167996 16.223363,27.820204 17.695487,24.84549
      20.911917,18.346067 12.479841,9.7162491 15.637016,3.1878367 21.063053,-8.0321371
      34.557465,-17.639322 47.011001,-17.150312 59.695727,-16.652225 71.382536,-5.252528
      77.244655,6.0073985 79.606312,10.543656 75.426853,16.86052 77.901899,21.33592
      c 3.613415,6.533807 15.270728,5.950588 18.444956,12.708671 8.213435,17.486796
      11.829985,48.895846 -5.166999,57.72815 C 77.2031,99.035616 36.588052,102.98905
      12.259458,90.686829 Z"
    />
    <path
      stroke="#008000"
      stroke-width="0.165"
      stroke-linecap="butt"
      d="M 49.247641,-28.25585 C 88.754918,-26.726418 117.7962,42.053366
      113.13982,75.702028 109.88723,99.206445 78.446522,110.10797 56.099312,111.04012
      32.229666,112.03578 -6.9943243,103.67968 -7.7589542,78.499629 -8.7953612,44.369677
      -5.9543226,-30.392865 49.247641,-28.25585 Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'AnimatedAgentSmith',

  mounted() {
    this.$refs.firstPath.addEventListener('animationend', () => {
      this.$emit('animated');
    });
  },
};
</script>

<style>
@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

svg path {
  animation: dash 2500ms linear forwards;
  fill: none;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
}
</style>
