<template>
  <v-container>
    <v-row>
      <v-col>
        <v-window v-model="step" :touchless="true">
          <v-window-item :value="0">
            <Intro @next="step=1;" />
          </v-window-item>

          <v-window-item :value="1">
            <Account @next="step=2;" @back="step=0;" />
          </v-window-item>

          <v-window-item :value="2">
            <Room @next="step=3;" @back="step=1;" />
          </v-window-item>

          <v-window-item :value="3">
            <Trigger @next="step=4;" @back="step=2;" />
          </v-window-item>

          <v-window-item :value="4">
            <Reaction @next="step=5;" @back="step=3;" />
          </v-window-item>

          <v-window-item :value="5">
            <Active @back="step=4;" />
          </v-window-item>
        </v-window>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Intro from '@/components/Intro.vue';
import Account from '@/components/Account.vue';
import Room from '@/components/Room.vue';
import Trigger from '@/components/Trigger.vue';
import Reaction from '@/components/Reaction.vue';
import Active from '@/components/Active.vue';

export default {
  name: 'AgentSmith',

  components: {
    Intro,
    Account,
    Room,
    Trigger,
    Reaction,
    Active,
  },

  data() {
    return {
      step: 0,
    };
  },
};
</script>
