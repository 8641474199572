<template>
  <v-container>
    <v-overlay
      :absolute="true"
      :value="waitingForServer"
    >
      <v-progress-circular indeterminate color="primary" />
    </v-overlay>

    <v-row>
      <v-col>
        <v-alert
          :value="errorMessage !== ''"
          outlined
          dense
          type="error"
          transition="scale-transition"
        >
          {{ errorMessage }}
        </v-alert>
      </v-col>
    </v-row>

    <v-row>
      <v-text-field
        label="Homeserver"
        placeholder="https://matrix-client.matrix.org"
        v-model="homeserver">
      </v-text-field>
    </v-row>

    <v-row>
      <v-text-field
        label="Username"
        v-model="username">
      </v-text-field>
    </v-row>

    <v-row>
      <v-text-field
        label="Password"
        :type="showPassword ? 'text' : 'password'"
        v-model="password"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showPassword = !showPassword"
      >
      </v-text-field>
    </v-row>

    <v-row>
      <BackNext
        :canGoNext="filledOut"
        @next="tryConnect"
        @back="$emit('back');"
      />
    </v-row>
  </v-container>
</template>

<script>
import { connectClient } from '@/assets/js/reaction';
import BackNext from '@/components/BackNext.vue';

export default {
  name: 'Account',

  components: {
    BackNext,
  },

  data() {
    return {
      homeserver: '',
      username: '',
      password: '',
      showPassword: false,
      waitingForServer: false,
      errorMessage: '',
    };
  },

  methods: {
    tryConnect() {
      this.waitingForServer = true;
      connectClient(
        this.homeserver,
        this.username,
        this.password,
      ).then(() => {
        this.errorMessage = '';
        this.waitingForServer = false;
        this.$emit('next');
      }, (err) => {
        this.waitingForServer = false;
        this.errorMessage = err.toString();
      });
    },
  },

  computed: {
    filledOut() {
      return (this.username !== '' && this.password !== '');
    },
  },
};
</script>
