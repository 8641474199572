<template>
  <v-container>
    <v-row>
      <div class="text-body-1">
        Agent Smith is a reaction bot for the <a target="_blank" href="https://matrix.org">Matrix</a>
        communication network. It can connect to a homeserver with a given account from
        the browser and listen for posts. It then reacts to specified <i>trigger words</i>
        and reacts with an emoji or a message.
      </div>
    </v-row>

    <v-row>
      <BackNext
        :backEnabled="false"
        :canGoNext="true"
        @next="$emit('next')"
      />
    </v-row>
  </v-container>
</template>

<script>
import BackNext from '@/components/BackNext.vue';

export default {
  name: 'Intro',

  components: {
    BackNext,
  },

  data() {
    return {
    };
  },
};
</script>
