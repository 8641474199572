<template>
  <v-container>
    <v-row>
      <v-col>
        <v-alert
          :type="alertType"
          transition="scale-transition"
          dense
          outlined
        >
          {{ alertMessage }}
        </v-alert>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-skeleton-loader
          type="list-item-three-line"
          :loading="waitingForServer"
        >
          <v-list rounded>
            <v-list-item-group v-model="selected" color="primary">
              <v-list-item
                v-for="(room, ii) in rooms"
                :key="ii"
              >
                <v-list-item-icon>
                  <v-icon>mdi-pound</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="room.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-skeleton-loader>
      </v-col>
    </v-row>

    <v-row>
      <BackNext
        @next="commitRoom"
        @back="$emit('back');"
        :canGoNext="roomSelected"
      />
    </v-row>
  </v-container>
</template>

<script>
import BackNext from '@/components/BackNext.vue';
import { getRooms, filterForRoom } from '@/assets/js/reaction';

export default {
  name: 'Room',

  components: {
    BackNext,
  },

  data() {
    return {
      selected: null,
      waitingForServer: true,
      rooms: [],
      errorMessage: '',
    };
  },

  mounted() {
    getRooms().then((theRooms) => {
      this.waitingForServer = false;
      this.rooms = theRooms;
    }, (error) => {
      this.waitingForServer = false;
      this.errorMessage = error.message;
    });
  },

  methods: {
    commitRoom() {
      filterForRoom(this.rooms[this.selected].roomId);
      this.$emit('next');
    },
  },

  computed: {
    roomSelected() {
      return Number.isInteger(this.selected);
    },

    alertType() {
      return (this.errorMessage === '' ? 'info' : 'error');
    },

    alertMessage() {
      let ret;
      if (this.errorMessage === '') {
        if (this.waitingForServer) {
          ret = 'Be patient, please...';
        } else {
          ret = 'Select your room:';
        }
      } else {
        ret = this.errorMessage;
      }

      return ret;
    },
  },
};
</script>
