<template>
  <v-container>
    <v-row>
      <v-col align="center">
        <v-btn
          v-if="backEnabled"
          :disabled="!canGoBack"
          outlined
          rounded
          color="secondary"
          @click="$emit('back');"
        >
          Back
        </v-btn>
      </v-col>

      <v-col align="center">
        <v-btn
          v-if="nextEnabled"
          outlined
          rounded
          :disabled="!canGoNext"
          color="primary"
          @click="$emit('next');"
        >
          Next
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'BackNext',

  props: {
    canGoBack: {
      default: true,
      type: Boolean,
    },

    canGoNext: {
      default: false,
      type: Boolean,
    },

    backEnabled: {
      default: true,
      type: Boolean,
    },

    nextEnabled: {
      default: true,
      type: Boolean,
    },
  },
};
</script>
