<template>
  <v-container>
    <v-row>
      <v-chip
        class="ma-1"
        color="accent"
        close
        v-for="(word, ii) in triggerWords"
        :key="ii"
        @click:close="removeTriggerWord(ii);"
      >
        {{ word }}
      </v-chip>
    </v-row>

    <v-row>
      <v-text-field
        label="Trigger words separated by [space]"
        v-model="triggerLine"
        @input="editTriggerWord()"
      >
      </v-text-field>
    </v-row>

    <v-row>
      <BackNext
        :canGoNext="hasTrigger"
        @next="commitTriggerWords"
        @back="$emit('back');"
      />
    </v-row>
  </v-container>
</template>

<script>
import BackNext from '@/components/BackNext.vue';
import { filterForTrigger } from '@/assets/js/reaction';

export default {
  name: 'Trigger',

  components: {
    BackNext,
  },

  data() {
    return {
      triggerWords: [
        'agent',
        'smith',
      ],
      triggerLine: '',
    };
  },

  computed: {
    hasTrigger() {
      return this.triggerWords.length > 0;
    },
  },

  methods: {
    removeTriggerWord(index) {
      this.triggerWords.splice(index, 1);
    },

    editTriggerWord() {
      const regex = /\s*(?<word>\w+)\s/;
      if (regex.test(this.triggerLine)) {
        const tw = new Set(this.triggerWords);
        tw.add(this.triggerLine.match(regex).groups.word.toLowerCase());
        this.triggerWords = Array.from(tw);
        this.triggerLine = '';
      }
    },

    commitTriggerWords() {
      filterForTrigger(this.triggerWords);
      this.$emit('next');
    },
  },
};
</script>
