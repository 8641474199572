import sdk from 'matrix-js-sdk';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

let matrixClient;
let rxStream;
let reactionCallback;

const extractUser = /@(?<username>\S+):\S+/;

export function connectClient(homeserver, username, password) {
  let theHomeserver = 'https://matrix-client.matrix.org';

  if (homeserver && typeof homeserver !== 'string') {
    throw new Error('`homeserver` needs to be a string.');
  }

  if (homeserver && homeserver !== '') {
    theHomeserver = homeserver;
  }

  if (!username || typeof username !== 'string') {
    throw new Error('`username` needs to be a string.');
  }

  if (!password || typeof password !== 'string') {
    throw new Error('`password` needs to be a string.');
  }

  matrixClient = sdk.createClient(theHomeserver);
  return matrixClient.login('m.login.password', {
    user: username,
    password,
  });
}

export async function initSource() {
  if (!matrixClient) {
    throw new Error('The matrix client has not been initialised.');
  }

  if (rxStream) {
    throw new Error('The source has already been initialised.');
  }

  await matrixClient.startClient();

  rxStream = Observable.create((observer) => {
    matrixClient.on('Room.timeline', (event, room, toStartOfTimeline) => {
      observer.next({
        event,
        room,
        toStartOfTimeline,
      });
    });
  }).pipe(
    filter((roomEvent) => roomEvent.event.getType() === 'm.room.message'),
    filter((roomEvent) => roomEvent.event.event.sender.trim() !== roomEvent.room.myUserId.trim()),
  );
}

export async function getRooms() {
  await initSource();

  return new Promise((resolve, reject) => {
    /* eslint-disable no-unused-vars */
    matrixClient.once('sync', (state, prevState, res) => {
      if (state !== 'PREPARED') {
        reject(new Error(`ERROR: ${state}`));
      }

      resolve(matrixClient.getRooms().filter(
        (room) => !room.currentState.events.has('m.room.encryption'),
      ));
    });
  });
}

const hintToMe = (roomEvent) => {
  const msg = roomEvent.event.event.content.body;
  const me = roomEvent.room.myUserId.match(extractUser).groups.username;
  if (msg.includes(me)) {
    matrixClient.sendEvent(
      roomEvent.room.roomId,
      'm.room.message',
      {
        msgtype: 'm.text',
        body: 'I am a matrix reaction bot. Check me out at https://agent-smith.app.',
      },
      '',
    );
  }
};

export function filterForRoom(roomId) {
  if (!roomId || typeof roomId !== 'string') {
    throw new Error('`roomId` needs to be a string.');
  }

  rxStream = rxStream.pipe(
    filter((roomEvent) => roomEvent.room.roomId === roomId),
    tap(hintToMe),
  );
}

export function filterForTrigger(triggerWords) {
  rxStream = rxStream.pipe(
    filter((roomEvent) => {
      const msg = roomEvent.event.event.content.body;
      return triggerWords.some((word) => msg.toLowerCase().includes(word));
    }),
  );
}

export function reactWithEmoji(emoji) {
  return (roomEvent) => matrixClient.sendEvent(
    roomEvent.room.roomId,
    'm.reaction',
    {
      'm.relates_to': {
        rel_type: 'm.annotation',
        event_id: roomEvent.event.event.event_id,
        key: emoji.data,
      },
    },
    '',
  );
}

export function reactWithText(text) {
  return (roomEvent) => {
    const { sender } = roomEvent.event.event;
    const msg = roomEvent.event.event.content.body;
    return matrixClient.sendEvent(
      roomEvent.room.roomId,
      'm.room.message',
      {
        msgtype: 'm.text',
        body: text
          .replace('$AUTHOR', sender.match(extractUser).groups.username)
          .replace('$QUOTE', msg),
      },
      '',
    );
  };
}

export function setCallback(callback) {
  reactionCallback = callback;
}

export function attachSink(errorCallback) {
  rxStream.subscribe(
    reactionCallback,
    errorCallback,
  );
}
