<template>
  <v-app>
    <v-main>
      <v-row justify="center">
          <AnimatedAgentSmith @animated="agentDrawn" />
      </v-row>

      <v-row justify="center">
        <v-col cols="12" xs="12" md="8" lg="6">
          <v-slide-y-reverse-transition>
            <AgentSmith v-show="showAgent" />
          </v-slide-y-reverse-transition>
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>

<script>
import AgentSmith from '@/components/AgentSmith.vue';
import AnimatedAgentSmith from '@/components/AnimatedAgentSmith.vue';

export default {
  name: 'App',

  components: {
    AgentSmith,
    AnimatedAgentSmith,
  },

  methods: {
    agentDrawn() {
      this.showAgent = true;
    },
  },

  data() {
    return {
      showAgent: false,
    };
  },
};
</script>
