<template>
  <v-container>
    <v-row>
      <v-col>
        <v-tabs outlined v-model="reactionMethod">
          <v-tab>Emoji</v-tab>
          <v-tab>Message</v-tab>
        </v-tabs>

        <v-tabs-items v-model="reactionMethod">
          <v-tab-item>
            <v-row>
              <v-col align="center">
                <v-menu
                  v-model="emojiDialog"
                  :close-on-content-click="false"
                  min-width="325"
                  min-height="434"
                  top
                  transition="slide-y-reverse-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      outlined
                      rounded
                      v-bind="attrs"
                      v-on="on"
                      v-text="emojiButtonText"
                    >
                    </v-btn>
                  </template>

                  <VEmojiPicker :dark="true" @select="selectEmoji">
                  </VEmojiPicker>
                </v-menu>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item>
            <v-row>
              <v-col>
                <v-textarea
                  v-model="reactionMessage"
                  outlined
                  rounded
                  no-resize
                  color="primary"
                  label="Message"
                  :hint="reactionMessageHint"
                  rows=4
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <v-row>
      <BackNext
        @next="commitReaction"
        @back="$emit('back');"
        :canGoNext="methodSelected"
      />
    </v-row>
  </v-container>
</template>

<script>
import { VEmojiPicker } from 'v-emoji-picker';
import { reactWithEmoji, reactWithText, setCallback } from '@/assets/js/reaction';
import BackNext from '@/components/BackNext.vue';

export default {
  name: 'Reaction',

  components: {
    VEmojiPicker,
    BackNext,
  },

  data() {
    return {
      reactionMethod: null,
      reactionMessageHint: `
      Type $AUTHOR to reference the given post's author.
      Type $QUOTE to quote the post that is reacted to.
      `,
      reactionMessage: 'You have a problem with authority, Mr. Anderson.',
      reactionEmoji: null,
      emojiDialog: false,
    };
  },

  methods: {
    selectEmoji(emoji) {
      this.reactionEmoji = emoji;
      this.emojiDialog = false;
    },

    commitReaction() {
      if (this.reactionMethod === 0) {
        setCallback(reactWithEmoji(this.reactionEmoji));
      } else {
        setCallback(reactWithText(this.reactionMessage));
      }

      this.$emit('next');
    },
  },

  computed: {
    methodSelected() {
      return (this.reactionMethod === 0
        ? this.reactionEmoji !== null
        : this.reactionMessage !== ''
      );
    },

    emojiButtonText() {
      return (this.reactionEmoji
        ? `${this.reactionEmoji.data} Change`
        : 'Select'
      );
    },
  },
};
</script>
