<template>
  <v-container>
    <v-row>
      <v-col>
        <v-alert dense outlined :type="alertType">
          {{ errorMessage !== '' ? errorMessage : 'Agent Smith is running...' }}
        </v-alert>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="10">
        <v-alert dense outlined type="info" :v-show="errorMessage.length > 0">
          You need to keep this window/tab open while it is running.
        </v-alert>
      </v-col>
    </v-row>

    <v-row>
      <BackNext
        :nextEnabled="false"
        @back="refreshPage"
      />
    </v-row>
  </v-container>
</template>

<script>
import BackNext from '@/components/BackNext.vue';
import { attachSink } from '@/assets/js/reaction';

export default {
  name: 'Active',

  components: {
    BackNext,
  },

  data() {
    return {
      errorMessage: '',
    };
  },

  methods: {
    refreshPage() {
      document.location.reload();
    },
  },

  mounted() {
    attachSink((error) => {
      this.errorMessage = error.stack;
    });
  },

  computed: {
    alertType() {
      return (this.errorMessage === '' ? 'success' : 'error');
    },
  },
};
</script>
